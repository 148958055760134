.rowReport{
  color: white;
  font-weight: bold;
}

.rowReport td a{
  color: white;
}


@media print {

  body{
    margin: 20px;
  }

  .rowReport {
    color: black;
    font-weight: bold;
    line-height: 0;
  }

  .rowReport td{
    background: none !important; 
    color: black;
  }

  .rowReport td a{
    background-color: none;
    color: black;
    font-weight: bold;
    line-height: 0;
  }
  
}