.pro-sidebar {
    position: fixed !important;
}
[data-collapsed="on"]{
    margin-left: 80px;
    transition-timing-function: ease-out;
}

[data-collapsed="off"]{
    margin-left: 270px;
    transition-timing-function: ease-in-out;
}

[data-collapsed="none"]{
    margin-left: 0;
}

.main-content-transition {
    transition-property: margin-left;
    transition-duration: .25s;
}